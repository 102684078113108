<template>
  <AuthorEditViewVlm disabled />
</template>

<script>
import AuthorEditViewVlm from '@/views/author/vlm/AuthorEditViewVlm'

export default {
  name: 'AuthorViewVlm',
  components: {
    AuthorEditViewVlm
  }
}
</script>
